var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
};

$(document).ready(function() {

	// add mobile class to body if isMobile
		if(isMobile.any()) {
			$("body").addClass("mobile");
		}

		// Find all the iframes
		var $iframes = $( "iframe" );
		// Find & save the aspect ratio for all iframes
		$iframes.each(function () {
			$(this).data("ratio", this.height / this.width)
				// Remove the hardcoded width &#x26; height attributes
				.removeAttr("width")
				.removeAttr("height");
		});

		// make certain things happen on load and on resize
		$(window).resize(function () {
			// resize iframes based on aspect ratio
			$iframes.each( function() {
				// Get the parent container'&#x27;'s width
				var width = $(this).parent().width();
				$(this).width(width)
					.height(width * $(this).data("ratio"));
			});
		}).resize(); // Invoke the resize event immediately

		// jquery modal: https://github.com/kylefox/jquery-modal
		// open all links with data-modal as a modal
		$('a[data-modal]').click(function(event) {
			$(this).modal({
				fadeDuration: 250,
				fadeDelay: 0.80
			});
			return false;
		});
		// open certain modals on page load
		$('.modal.load').modal();

		$('body').on($.modal.BEFORE_BLOCK, function(event, modal) {
			$(this).toggleClass("active-modal");
		});
		$('body').on($.modal.AFTER_CLOSE, function(event, modal) {
			$(this).toggleClass("active-modal");
		});

		// mainNav active toggle
		// $('#mainNavTrigger').click(function() {
		// 	$('body').toggleClass('nav-active');
		// 	return false
		// });

	// entire block clickable
	/*
	$(".widget ul.grid li").click(function()
		{window.location=$(this).find("a").attr("href"); return false;}
	);
	*/
	
	$("a[href^='http']").not("[href*='millcreeklaw.dev']").not("[href*='millcreeklaw.com']").attr('target','_blank');

});
